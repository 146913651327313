import { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Radio,
  Button,
  Select,
  Form,
  message,
  DatePicker,
  Upload,
} from "antd";
import { FileOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EhrPopup from "./EhrPopUp";

const ClinicalNotePatientForm = ({
  isOpen,
  setOpenPatientClinicalForm,
  edit,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fetchingForms, setFetchingForms] = useState(false);
  const [data, setData] = useState([]);
  const [name,setName]=useState("");
  const [dob,setDob]=useState("");
  const [startDate,setStartDate]=useState("");
  const [endDate,setEndDate]=useState("");
  const [formOptions, setFormOptions] = useState([]);
  const [disableProceed, setDisableProceed] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [ehrLoading,setEhrLoading]=useState(false);
  const [ehrData,setEhrData]=useState({});
  const [ehrOpen,setEhrOpen]=useState(false);

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        patientName: edit.patient_name,
        dateOfBirth: data.patient_dob ? dayjs(data.patient_dob) : null,
        formType: edit.form_type,
        relevantForm: edit.form_name,
      });
      setDisableProceed(false);
      setUploadDisabled(false);
    } else {
      form.resetFields();
      setDisableProceed(true);
      setUploadDisabled(true);
    }
  }, [edit, form]);

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (data.length > 0) {
        sessionStorage.setItem("clinical-data", JSON.stringify(data));
      } else {
        const mewData = [
          {
            patient_name: values.patientName,
            patient_dob: values.dateOfBirth.format("YYYY-MM-DD"),
            form_type: values.formType,
          },
        ];
        sessionStorage.setItem("clinical-data", JSON.stringify(mewData));
      }
      message.success("Patient information submitted successfully!");
      navigate("/generate-clinical-note");
      form.resetFields();
      setOpenPatientClinicalForm(!isOpen);
    } catch (error) {
      console.log(error);
      message.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const disableFutureDates = (current) =>
    current && current > dayjs().endOf("day");

  const fetchEHR = async () => {
      const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}/receiver/?full_name=${encodeURIComponent(name)}&dob=${encodeURIComponent(dob)}&begin=${encodeURIComponent(startDate)}&end=${encodeURIComponent(endDate)}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json'
      },
      data: {
      
      }
    };
    setEhrLoading(true);
    try {
      const response = await axios(config);
      sessionStorage.setItem("ehr-data",JSON.stringify(response.data));
      setEhrData(response?.data)
      message.success("EhR data has been fetched successfully!")
      setEhrLoading(false);
      
    } catch (error) {
      console.log(error)
      setEhrLoading(false);
      if(error.response.status == 404){
        message.error("Patient not found")
        return
      }
      message.error("Error in fetching EHR data")
      if (error.response) {
        console.error(`API Error: ${error.response.status} - ${error.response.data}`);
      } else if (error.request) {
        console.error("No response received from the API:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const fetchRelevantForms = async () => {
    try {
      setFetchingForms(true);
      const { patientName, email, dateOfBirth, formType } =
        form.getFieldsValue();
      if (!patientName || !dateOfBirth || !formType) {
        message.warning("Please fill out all fields before selecting a form");
        setFormOptions([]);
        setUploadDisabled(true);
        return;
      }
      const formattedDOB = dateOfBirth.format("YYYY-MM-DD");
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/list_forms/",
        {
          patient_name: patientName,
          patient_dob: formattedDOB,
          form_type: formType,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const data = response.data;
      if (data && data.length > 0) {
        setData(
          data.map((form) => ({
            patient_name: form.patient_name,
            file_url: form.file_url,
            form_json: form.form_json,
            form_name: form.form_name,
            patient_dob: form.patient_dob,
            form_type: form.form_type,
          }))
        );

        setFormOptions(
          data.map((form) => ({
            id: form.form_name,
            value: form.form_name,
            label: form.form_name,
          }))
        );
        setDisableProceed(false);
        setUploadDisabled(false);
      } else {
        message.info("No relevant forms exist");
        setFormOptions([]);
        setUploadDisabled(true);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        message.error("You are not authorized! please login");
        return;
      }
      if (error?.response?.status === 403) {
        message.error("You are not authorized! please verified your email");
        return;
      }
      message.error("Error fetching forms");
    } finally {
      setFetchingForms(false);
    }
  };

  const handleDateChange = (date, dateString) => {
    setDob(dateString);
  };
  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };
  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString);
  };
  const viewEhr = ()=>{
    setEhrOpen(true);
  }
 

  return (
    <>
    <Modal
      title={<span className="text-lg font-medium">Patient Information</span>}
      open={isOpen}
      onCancel={() => setOpenPatientClinicalForm(!isOpen)}
      footer={null}
      className="w-full"
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="space-y-4 py-4"
        requiredMark={false}
      >
        <Form.Item
          label="Name of the Patient"
          name="patientName"
          rules={[
            { required: true, message: "Please enter patient name" },
            { min: 2, message: "Name must be at least 2 characters" },
            { max: 50, message: "Name cannot exceed 50 characters" },
            {
              pattern: /^[a-zA-Z\s]*$/,
              message: "Name can only contain letters and spaces",
            },
          ]}
        >
          <Input placeholder="Eg: James Franklin"  value={name} onChange={(e)=> setName(e.target.value)}  className="w-full p-2" />
        </Form.Item>

 

        <Form.Item
          label="Patient Date of Birth"
          name="dateOfBirth"
          rules={[
            { required: true, message: "Please select date of birth" },
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                const year = value.year();
                if (year < 1900) return Promise.reject("Invalid year");
                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            className="w-full p-2"
            format="YYYY-MM-DD"
            disabledDate={disableFutureDates}
            onChange={handleDateChange}
            placeholder="Select date of birth"
          />
        </Form.Item>
        <Form.Item
          label="Start & End date to fetch EHR"
          name="enddate"
          rules={[
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                const year = value.year();
                if (year < 1900) return Promise.reject("Invalid year");
                return Promise.resolve();
              },
            },
          ]}
        >
          <div className="flex gap-x-8">
          <DatePicker
            className="w-1/3 p-2"
            format="YYYY-MM-DD"
            disabledDate={disableFutureDates}
            onChange={handleStartDateChange}
            placeholder="Select Start date"
          />
          <DatePicker
            className="w-1/3 p-2"
            format="YYYY-MM-DD"
            disabledDate={disableFutureDates}
            onChange={handleEndDateChange}
            placeholder="Select End Date"
          />
          </div>
        </Form.Item>

        <Form.Item
          label="Select Patient Form"
          name="formType"
          rules={[
            { required: true, message: "Please select patient form type" },
          ]}
        >
          <Radio.Group className="flex gap-4">
            <Radio value="adult">Adult</Radio>
            <Radio value="pediatric">Pediatric</Radio>
            <Radio value="neonatal">Neonatal</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="relevantForm"
          rules={[
            { required: false, message: "Please select a relevant form" },
          ]}
        >
          <Select
            className="w-full"
            placeholder={
              <div className="flex items-center gap-2">
                <FileOutlined />
                <span>Select Relevant form</span>
              </div>
            }
            suffixIcon={<span className="text-xs">▼</span>}
            options={formOptions}
            onDropdownVisibleChange={fetchRelevantForms}
            loading={fetchingForms}
            onChange={() => setUploadDisabled(false)}
          />
        </Form.Item>

        <Form.Item
          label="Upload Patient Intake Form"
          name="pdfUpload"
          className="mt-4"
        >
          <Upload
            accept=".pdf"
            className="w-full"
            disabled={uploadDisabled}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />} disabled={uploadDisabled}>
              Upload PDF
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="Upload from Electronic Health Records - EHR/EMR">
        <div className="flex gap-3">
          <Button loading={ehrLoading} disabled={!name || !dob} icon={<UploadOutlined />} onClick={fetchEHR}>
            Upload EHR
          </Button>
          {Object.keys(ehrData).length > 1  && <Button onClick={viewEhr}>
            View EHR
          </Button>}
          </div>
        </Form.Item>

        <Form.Item className="mb-0">
          <div className="flex justify-center mt-6">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="primary text-white w-44"
            >
              Proceed Information
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
    {Object.keys(ehrData).length > 1 &&<EhrPopup visible={ehrOpen} onClose={setEhrOpen} data={ehrData} />}
    </>
  );
};

export default ClinicalNotePatientForm;
