import React, { useEffect, useState } from "react";
import { AppRoutesConst } from "../../app-const";
import { Link, useLocation } from "react-router-dom";
import { Col, Drawer, Menu, Row } from "antd";
import "./index.css";
import { CloseOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import useScreenType from "react-screentype-hook";
import Logo from "../../assets/images/AIMedbox_Logo.svg";
import { useAuth0, LogoutOptions } from "@auth0/auth0-react";

interface Props {
  inInline: boolean;
  open: boolean;
  token: string | null;
  selectedKey: any;
  setSelectedKey: React.Dispatch<React.SetStateAction<any>>;
  setOpenMenu: React.Dispatch<React.SetStateAction<any>>;
}

function AppMenu(props: Props) {
  const { loginWithRedirect, logout } = useAuth0();
  const handleLogout = () => {
    logout({ returnTo: window.location.origin } as LogoutOptions);
  };

  const handleMenuSelect = ({ key }: { key: string }) => {
    props.setSelectedKey(key);
    localStorage.setItem("selectedKey", key);
  };
  const screenType = useScreenType();
  return (
    <Menu
      mode={props.inInline ? "inline" : "horizontal"}
      className="justify-content-center"
      defaultSelectedKeys={["home"]}
      onSelect={handleMenuSelect}
      selectedKeys={[props.selectedKey]}
    >
      <Menu.Item key="home">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to="/"> Home </Link>
        </button>
      </Menu.Item>
      <Menu.Item key="clinical-note">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.clinicalNote}>
            <span className={"nav-black-color"}> Clinical Notes</span>
          </Link>
        </button>
      </Menu.Item>
      <Menu.Item key="discharge-summary">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.dischargeSummary}>Discharge Summary</Link>
        </button>
      </Menu.Item>
      <Menu.Item key="case-report">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.caseReport}>Case Report</Link>
        </button>
      </Menu.Item>

      <Menu.Item key="chatmed">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.medboxbot}>ChatMed</Link>
        </button>
      </Menu.Item>
      <Menu.Item key="search-treatment">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.searchTreatment}>
            <span className={"nav-black-color"}> Search Treatment</span>
          </Link>
        </button>
      </Menu.Item>
      <Menu.Item key="drug-dosage-research">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.drugDosage}>
            <span className={"nav-black-color"}> Drug Dosage</span>
          </Link>
        </button>
      </Menu.Item>
      {/* <Menu.Item key="Resources">
      <button onClick={()=>props.setOpenMenu(false)}>
        <Link to={AppRoutesConst.resources}>
          <span className={"nav-black-color"}> Resources</span>
        </Link>
        </button>
      </Menu.Item> */}
      <Menu.Item key="contributors">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.contributors}>
            <span className={"nav-black-color"}> Contributors</span>
          </Link>
        </button>
      </Menu.Item>
      <Menu.Item key="membership">
        <button onClick={() => props.setOpenMenu(false)}>
          <Link to={AppRoutesConst.membership}>
            <span className={"nav-black-color"}> Membership</span>
          </Link>
        </button>
      </Menu.Item>

      {props.open && (
        <>
          {props.token ? (
            <Menu.Item
              className={"nav-black-color"}
              onClick={handleLogout}
              key="logout"
            >
              <button onClick={() => props.setOpenMenu(false)}>
                <FontAwesomeIcon icon={faRightToBracket} /> LogOut
              </button>
            </Menu.Item>
          ) : (
            <>
              <Menu.Item
                  onClick={() => {
                    localStorage.setItem("action", "login");
                    loginWithRedirect();
                  }}
                className={"nav-black-color"}
                key="login"
              >
                <FontAwesomeIcon icon={faRightToBracket} /> Login
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  localStorage.setItem("action", "signup");
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: "signup",
                    },
                  });
                }}
                //  onClick={() => loginWithRedirect({ screen_hint: 'signup' } as ExtendedRedirectLoginOptions)}

                className={"nav-black-color"}
                key="signup"
              >
                <FontAwesomeIcon icon={faUserPlus} flip={"horizontal"} /> Sign
                Up
              </Menu.Item>
            </>
          )}
        </>
      )}
    </Menu>
  );
}

export default function MainHeader() {
  const { loginWithRedirect, logout } = useAuth0();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState<any>(null);
  const [token, setToken] = useState<string | null>(null);
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    logout({ returnTo: window.location.origin } as LogoutOptions);
   
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleMenuSelect = ({ key }: { key: string }) => {
    setSelectedKey(key);
    localStorage.setItem("selectedKey", key);
  };

  useEffect(() => {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    const pathSegments = pathname.split('/');
    const page = pathSegments[1]; 
    

    if (page =="") {
      localStorage.setItem("selectedKey", "home");
 }
 else{
  localStorage.setItem("selectedKey", page);

 }

  }, []);
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setToken(token);
    } else {
      setToken(null);
    }
    const storedKey = localStorage.getItem("selectedKey");
    if (location.pathname === "/contributors") {
      setSelectedKey("contact us");
      return;
    }
    if (storedKey) {
      setSelectedKey(storedKey);
      console.log(location);
    }
  }, [location]);

  const screenType = useScreenType();
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="main-header">
      <div className="top-header">
        <div className="container">
          <Row className="flex justify-between items-center">
            <div className="flex justify-start items-center">
              <Col>
                <Link to="/">
                  <img
                    src={Logo}
                    alt={"logo"}
                    className="w-full h-[30px] md:h-[60px]"
                  />
                </Link>
              </Col>

              {/* <p className=" px-4 hidden md:flex w-[220px] md:w-[400px] text-[10px] md:text-sm text-wrap  leading-[14px] md:leading-7">AiMedbox Intelligent clinical tools - treatment , Case report generator, ChatMed</p>
              <p className="px-4 flex md:hidden  text-[10px] md:text-[12px] text-wrap  leading-[14px] md:leading-7">AiMedbox Intelligent clinical tools</p> */}
            </div>

            <Col className="justify-end">
              {!openMenu && !screenType.isMobile && !screenType.isTablet ? (
                <Menu
                  mode={token ? "inline" : "horizontal"}
                  className="justify-content-center"
                  onSelect={handleMenuSelect}
                  selectedKeys={[selectedKey || ""]}
                >
                  {token ? (
                    <Menu.Item
                      className={"nav-black-color"}
                      onClick={handleLogout}
                      key="logout"
                    >
                      <FontAwesomeIcon icon={faRightToBracket} /> LogOut
                    </Menu.Item>
                  ) : (
                    <div>
                      <Menu.Item
                        onClick={() => {
                          localStorage.setItem("action", "login");
                          handleLogin();
                        }}
                        className={"nav-black-color"}
                        key="login"
                      >
                        <FontAwesomeIcon icon={faRightToBracket} /> Login
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          localStorage.setItem("action", "signup");
                          loginWithRedirect({
                            authorizationParams: {
                              screen_hint: "signup",
                            },
                          });
                        }}
                        className={"nav-black-color"}
                        key="signup"
                      >
                        <FontAwesomeIcon icon={faUserPlus} /> Sign Up
                      </Menu.Item>
                    </div>
                  )}
                </Menu>
              ) : (
                <MenuFoldOutlined
                  onClick={() => {
                    setOpenMenu(true);
                  }}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="header">
        <div className="">
          <Row>
            <Col md={24} lg={24}>
              {!screenType.isMobile && !screenType.isTablet ? (
                <AppMenu
                  inInline={false}
                  open={openMenu}
                  token={token}
                  selectedKey={selectedKey}
                  setSelectedKey={setSelectedKey}
                  setOpenMenu={setOpenMenu}
                />
              ) : (
                <Drawer
                  open={openMenu}
                  closable={false}
                  onClose={() => {
                    setOpenMenu(false);
                  }}
                >
                  <div className="header-drawer">
                    <CloseOutlined
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    />
                    <AppMenu
                      inInline={true}
                      open={openMenu}
                      token={token}
                      selectedKey={selectedKey}
                      setSelectedKey={setSelectedKey}
                      setOpenMenu={setOpenMenu}
                    />
                  </div>
                </Drawer>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
