import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  Select,
  Radio,
  notification,
  Form,
  Spin,
  message,
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const { Option } = Select;
const countries = [
  "United States",
  "Canada",
  "Pakistan",
  "India",
  "United Kingdom",
  "Australia",
  "Germany",
  "France",
  "China",
  "Japan",
];

const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Mandarin",
  "Hindi",
];

const specialties = [
  "Allergy And Immunology",
  "Anesthesiology",
  "Cardiology",
  "Dermatology",
  "Emergency",
  "Endocrinology",
  "Epidemiology",
  "Gastroenterology",
  "Genetics",
  "Geriatrics",
  "Gynecology",
  "Hospitalist",
  "Hematology",
  "Hepatology",
  "Infectious Disease",
  "Internal Medicine",
  "Neonatology",
  "Nephrology",
  "Neurology",
  "Neurosurgery",
  "Obstetrics Gynecology",
  "Oncology",
  "Ophthalmology",
  "Orthopedics",
  "Otolaryngology",
  "Pain Management",
  "Palliative Care",
  "Pediatrics",
  "Podiatry",
  "Preventive Medicine",
  "Psychiatry",
  "Pulmonology",
  "Radiology",
  "Rehabilitation Medicine",
  "Research",
  "Rheumatology",
  "Sleep Medicine",
  "Surgery",
  "Toxicology",
  "Transplantation",
  "Traumatology",
  "Urology",
  "Vascular Surgery",
  "Venereology",
];

const options = [
  { label: "Free", value: "free" },
  { label: "Enterprise", value: "enterprise" },
];

const AdditionalInfoForm = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const [plan, setPlan] = useState("free");
  const [profession, setProfession] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const access_token = await getAccessTokenSilently();
        console.log(access_token);
        setAccessToken(access_token);
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/signup/",
          {
            token: access_token,
          }
        );

        if (response.status === 200 || response.status === 201) {
          message.success("Login successful!");
          localStorage.setItem("accessToken", response.data.token);
          navigate("/");
        }
      } catch (error) {
        if (
          error?.response?.status === 403 &&
          localStorage.getItem("action") === "signup"
        ) {
          message.info(
            "Signup successfull please verify your email and login."
          );
          setIsModalVisible(true);
          return;
        }
        if (error?.response?.status === 403) {
          message.error("Verify your email and login.");
          navigate("/");
          return;
        }
        if (error?.response?.status === 500) {
          message.error("Server Error.");
          navigate("/");
          return;
        }
      }
    };

    fetchData();
  }, []);

  const onChangePlan = (value) => {
    setPlan(value);
  };

  const handleSubmit = async (values) => {
    let userProfile;

    if (values.profession === "Physician") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        physician: true,
        speciality: values.speciality,
        npi: values.npi,
        plan_type: plan,
        ehr_info: values.ehrInfo,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    } else if (values.profession === "Medical Student") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        medical_student: true,
        school_name: values.schoolName,
        plan_type: plan,
        ehr_info: values.ehrInfo,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    } else if (values.profession === "Physician Assistant") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        physician_assistant: true,
        plan_type: plan,
        ehr_info: values.ehrInfo,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    } else if (values.profession === "Other") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        other_role: values.other_role,
        plan_type: plan,
        ehr_info: values.ehrInfo,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    }

    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/add-extra-info/",
        userProfile
      );
      console.log(response.data);
      message.success("Registration successful.");
      setIsModalVisible(false);
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      message.error("Registration failed.");
    }
  };

  return (
    <>
      {isModalVisible ? (
        <Modal
          width={460}
          title="User Registration - Additional Information"
          visible={isModalVisible}
          maskClosable={false}
          onCancel={() => {
            setIsModalVisible(false);
            navigate("/");
          }}
          footer={[
            <Button
              key="skip"
              onClick={() => {
                setIsModalVisible(false);
                navigate("/");
              }}
            >
              Skip for Now
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              className="primary"
              onClick={() => form.submit()}
            >
              Continue
            </Button>,
          ]}
        >
          <Form
            form={form}
            requiredMark={false}
            onFinish={handleSubmit}
            onValuesChange={(changedValues, allValues) => {
              if ("profession" in changedValues) {
                setProfession(changedValues.profession);
              }
            }}
            layout="vertical"
            className="w-full"
            initialValues={{
              country: countries[0],
              language: languages[0],
              speciality: specialties[0],
              plan:"free",
            }}
          >
            <div className="flex gap-8 items-center w-full">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "First name is required." },
                  {
                    pattern: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                    message: "Only alphabets allowed, no leading spaces.",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Last name is required." },
                  {
                    pattern: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                    message: "no leading spaces allowed",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
            </div>

            <div className="flex gap-8 items-center w-full">
              <Form.Item
                label="Select Country"
                name="country"
                className="w-[45%]"
                rules={[{ required: true, message: "Country is required." }]}
              >
                <Select>
                  {countries.map((country) => (
                    <Option key={country} value={country}>
                      {country}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Zip Code"
                name="zipCode"
                rules={[
                  { required: true, message: "Zip code is required." },
                  {
                    pattern: /^[0-9]{5}$|^[A-Za-z0-9]{3,10}$/,
                    message:
                      "Enter a valid zip code (5 digits or alphanumeric).",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className="flex gap-8 items-center w-full">
              <Form.Item
                label="EHR Information"
                name="ehrInfo"
                className="w-[45%]"
                rules={[
                  { required: true, message: "EHR information is required." },
                  {
                    pattern: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                    message: "Only alphabets allowed, no leading spaces.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Plan Type" name="plan" default>
                <Radio.Group
                  options={options}
                  onChange={onChangePlan}
                  value={plan}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>

            <Form.Item
              label="Select Language"
              name="language"
              rules={[{ required: true, message: "Language is required." }]}
            >
              <Select>
                {languages.map((language) => (
                  <Option key={language} value={language}>
                    {language}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Select Profession"
              name="profession"
              rules={[{ required: true, message: "Profession is required." }]}
            >
              <Radio.Group className="flex flex-col">
                {[
                  "Physician",
                  "Medical Student",
                  "Physician Assistant",
                  "Other",
                ].map((profession) => (
                  <Radio key={profession} value={profession}>
                    {profession}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {profession === "Physician" && (
              <>
                <Form.Item
                  label="Select Speciality"
                  name="speciality"
                  rules={[
                    { required: true, message: "Speciality is required." },
                  ]}
                >
                  <Select>
                    {specialties.map((speciality) => (
                      <Option key={speciality} value={speciality}>
                        {speciality}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Provide NPI"
                  name="npi"
                  rules={[
                    { required: true, message: "NPI is required." },
                    {
                      pattern: /^\d{10}$/,
                      message: "NPI must be exactly 10 digits.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}

            {profession === "Medical Student" && (
              <Form.Item
                label="School Name"
                name="schoolName"
                rules={[
                  { required: true, message: "School name is required." },
                  {
                    pattern: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                    message: "Only alphabets allowed, no leading spaces.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {profession === "Other" && (
              <Form.Item
                label="Profession Name"
                name="other_role"
                rules={[
                  { required: true, message: "Profession name is required." },
                  {
                    pattern: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                    message: "Only alphabets allowed, no leading spaces.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Form>
        </Modal>
      ) : (
        <div className="flex justify-center h-screen items-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default AdditionalInfoForm;
